import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import ContentLayout from "../layout/ContentLayout";
import AdminContext from "../../store/admin-context";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Loader from "@amzn/meridian/loader";
import {
  ALERT_DEFAULT_MESSAGE,
  ALERT_AUTHENTICATION_MESSAGE,
  COLUMN_TYPES,
  CALM_CODE_SUBMIT_SUCCESS_STATUS,
  TOASTER_TIMEOUT_OFFSET, LMS_COLUMN_TYPES
} from "../../constants";
import AlertErrorWithMessage from "../alert/AlertErrorWithMessage";
import LMSManualLaborTrackContext from "../../store/lms-manual-labor-track-store";
import LMSManualLaborTrackColumnList from "./LMSManualLaborTrackColumnList";
import LMSManualLaborTrackMessageToast from "./LMSManualLaborTrackMessageToast";
import StartLMSManualLaborTrackModal from "../modal/StartLMSManualLaborTrackModal";
import {
  EmployeeTrainedLMSCertificate,
  EmployeeTrainedLMSCertificates,
  LMSLaborTrackingRole
} from "../../admin-api/generated-src";
import AdminApiFactory from "../../admin-api/AdminApiFactory";

let dummyId = 0;

export default function LMSManualLaborTrackPage() {
  const adminContext = useContext(AdminContext);
  const manualLaborTrackContext = useContext(LMSManualLaborTrackContext);
  const { selectedNode, changeSelectedNode } = adminContext;
  const { nodeId } = useParams<{ nodeId: string }>();
  const { employeeId,
    setEmployeeId,
    employeeTrainedCertificates,
    setEmployeeTrainedLMSCertificates,
    laborTrackCertificateList,
    setLaborTrackCertificateList,
    showModal,
    setShowModal,
    selectedCertificateName,
    setSelectedCertificateName,
    setShowRadioButton
  } = manualLaborTrackContext;
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(ALERT_DEFAULT_MESSAGE);
  const [hasPermission, setHasPermission] = useState(true);
  const [flipReloadFlag, setFlipReloadFlag] = useState(false);
  const [toasts, setToasts] = useState<{ id: string, timeout: number }[]>([]);
  const [manualLaborTrackResponseMessage, setLMSManualLaborTrackResponseMessage] =
    useState<any>("");

  const AdminApi = AdminApiFactory();

  useEffect(() => {
    if (nodeId !== selectedNode) {
      changeSelectedNode(nodeId);
    }
  }, [nodeId]);

  const cleanup = () => {
    setEmployeeTrainedLMSCertificates({});
    setLaborTrackCertificateList([]);
    setShowRadioButton(false);
    setShowAlert(false);
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const lhqCertificatesResponse: LMSLaborTrackingRole[] = (await AdminApi.getLmsCertificatesAndCustomRolesForSite(nodeId)).data as LMSLaborTrackingRole[];
        if (lhqCertificatesResponse && lhqCertificatesResponse.length) {
          setLaborTrackCertificateList(lhqCertificatesResponse);
        }
      } catch (ex: any) {
        setShowAlert(true);
        console.error(
          `Error GET /admin/lhqCertificate/${nodeId}/`,
          ex
        );
        if (ex.response && ex.response.status === 403) {
          setAlertMessage(ALERT_AUTHENTICATION_MESSAGE);
          setHasPermission(false);
        } else {
          setAlertMessage(ALERT_DEFAULT_MESSAGE);
        }
      } finally {
        setIsLoading(false);
        setShowRadioButton(false);
      }
    })();
    return cleanup;
  }, [flipReloadFlag]);

  const getEmployeeTrainedLMSCertificateHandler = () => {
    if (!employeeId) {
      return;
    }

    (async () => {
      setIsLoading(true);
      cleanup();
      try {
        const employeeTrainedCertificatesResponse: EmployeeTrainedLMSCertificates = (
          await AdminApi.getLMSCertificatesForEmployee(nodeId, employeeId.trim())
        ).data;
        if (
          employeeTrainedCertificatesResponse &&
          employeeTrainedCertificatesResponse.trainedCertificates
        ) {
          setEmployeeTrainedLMSCertificates(employeeTrainedCertificatesResponse);
          setLaborTrackCertificateList(employeeTrainedCertificatesResponse.trainedCertificates);
          setShowAlert(false);
          setShowRadioButton(true);
        }
      } catch (ex) {
        console.error(
          `Error fetching /admin/laborTrack/${nodeId}/${employeeId}`,
          ex
        );
        setShowAlert(true);
      } finally {
        setIsLoading(false);
        setSelectedCertificateName("");
      }
    })();
  };

  const startLMSManualLaborTrackOnCertificateHandler = () => {
    const { name, alias, badgeId, employeeId = "" } = employeeTrainedCertificates;
    const trimmedEmployeeId = employeeId.trim();
    const filterTrainedCertificateFromCertificateName = laborTrackCertificateList.filter((role) => role.certificateName === selectedCertificateName);
    const trainedCertificateFromCertificateName: LMSLaborTrackingRole | undefined = filterTrainedCertificateFromCertificateName.length ? filterTrainedCertificateFromCertificateName[0] : undefined;
    let rightNow = new Date();
    const startLMSManualLaborTrackForEmployeeRequest: EmployeeTrainedLMSCertificate = {
      name,
      alias,
      badgeId,
      employeeId: trimmedEmployeeId,
      // https://stackoverflow.com/questions/1486476/json-stringify-changes-time-of-date-because-of-utc
      scanTimestamp: new Date(rightNow.getTime() - (rightNow.getTimezoneOffset() * 60000)).toJSON(),
      trainedCertificate: trainedCertificateFromCertificateName
    };

    (async () => {
      try {
        const startLMSManualLaborTrackResponse = (await AdminApi.startLMSManualLaborTrackingForEmployee(
          nodeId,
          trimmedEmployeeId,
          startLMSManualLaborTrackForEmployeeRequest)).data as any;
        setLMSManualLaborTrackResponseMessage(startLMSManualLaborTrackResponse === CALM_CODE_SUBMIT_SUCCESS_STATUS ?
          `${alias}(${trimmedEmployeeId}) was labor tracked successfully for ${selectedCertificateName}!` :
          startLMSManualLaborTrackResponse
        );
        onOpenToast(startLMSManualLaborTrackResponse);
      } catch (ex: any) {
        setLMSManualLaborTrackResponseMessage(`Error start manual labor track for ${alias}(${trimmedEmployeeId}): ${ex.message}`);
        onOpenToast(ex.message);
        console.error(`Error start manual labor track for ${alias}(${trimmedEmployeeId})`, ex);
      } finally {
        setShowModal(false);
        setFlipReloadFlag(!flipReloadFlag);
        setEmployeeId("");
      }
    })();
  }

  const onConfirmClose = useCallback(() => {
    setSelectedCertificateName("")
    setShowModal(false)
  }, []);
  const onCloseToast = useCallback(
    (id) => setToasts(toasts.filter((t) => t.id !== id)),
    [toasts]
  );
  const onOpenToast = useCallback(
    (responseMessage) => setToasts((toasts as any).concat(responseMessage === CALM_CODE_SUBMIT_SUCCESS_STATUS ?
      { id: `${++dummyId}`, timeout: TOASTER_TIMEOUT_OFFSET } : { id: `${++dummyId}` })),  // timeout only for success message for better UX
    [toasts]
  );

  return (
    <ContentLayout nodeId={nodeId} helperText="">
      {showAlert && <AlertErrorWithMessage message={alertMessage} />}
      <StartLMSManualLaborTrackModal
        open={showModal}
        onClose={onConfirmClose}
        confirmHandler={startLMSManualLaborTrackOnCertificateHandler}
      />
      <LMSManualLaborTrackMessageToast
        toasts={toasts}
        onCloseToast={onCloseToast}
        message={manualLaborTrackResponseMessage}
      />
      {hasPermission && <form onSubmit={getEmployeeTrainedLMSCertificateHandler}><Column width="100%">
        <Row>
          <Input
            width={450}
            placeholder="Enter employee's alias or badge id"
            value={employeeId}
            onChange={setEmployeeId}
            inaccessibleAutoFocus
          />
          <Button submit>Search</Button>
        </Row>
        {isLoading ? <Loader /> : <Row alignmentVertical="top">
          {LMS_COLUMN_TYPES.map((type) => (
            <LMSManualLaborTrackColumnList
              key={type}
              manualLaborTrackCertificates={laborTrackCertificateList}
              type={type}
            />
          ))}
        </Row>}
      </Column>
      </form>}
    </ContentLayout>
  );
}
