import React, {useContext} from "react";
import Button from "@amzn/meridian/button";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import LMSManualLaborTrackContext from "../../store/lms-manual-labor-track-store";

export default function StartManualLaborTrackModal({open, onClose, confirmHandler}) {
  const manualLaborTrackContext = useContext(LMSManualLaborTrackContext);
  const {employeeId, selectedCertificateName} = manualLaborTrackContext;

  return (
      <React.Fragment>
        <Modal
            open={open}
            title=""
            onClose={onClose}
            scrollContainer="viewport"
            width={350}
            closeLabel="Close"
        >
        <span id="modal-description">
          {`You are about to labor track `}<b>{employeeId}</b>{` into "${selectedCertificateName}". Are you sure?`}
        </span>
          <ModalFooter>
            <Row alignmentHorizontal="right" widths="fit">
              <Button
                  type="secondary"
                  size="small"
                  onClick={onClose}
                  minWidth={60}
              >
                Cancel
              </Button>
              <Button
                  type="primary"
                  size="small"
                  onClick={confirmHandler}
                  minWidth={60}
              >
                OK
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
}