import React, { useState, useContext, useEffect, useCallback } from "react";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import Select, { SelectOption } from "@amzn/meridian/select";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import AdminContext from "../../../store/admin-context";
import { useParams } from "react-router-dom";
import ContentLayout from "../../layout/ContentLayout";
import AdminApiFactory from '../../../admin-api/AdminApiFactory';
import Button from "@amzn/meridian/button";
import "./UploadRosterPage.scss";
import Row from "@amzn/meridian/row";
import UploadRosterSubmitModal from "../../modal/UploadRosterSubmitModal";
import { 
    UPLOAD_ROSTER_PROCESSING_MESSAGE,
    ALERT_AUTHENTICATION_MESSAGE,
    UPLOAD_ROSTER_SUBMIT_ERROR_MESSAGE,
    SHIFTID } from "../../../constants";

export default function UploadRosterPage() {
    const [shiftType, setShiftType] = useState();
    const [newShiftType, setNewShiftType] = useState("");
    const adminContext = useContext(AdminContext);
    const {username, selectedNode, changeSelectedNode} = adminContext;
    const { nodeId } = useParams<{ nodeId: string }>();
    const AdminApi = AdminApiFactory();
    const [selectedFile, setSelectedFile] = useState(null);
    const [ready, setReady] = useState(false);
    const [files, setFiles] = useState([] as any);
    const [openModal, setOpenModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState<any>();

    let date = new Date();
    const shiftDate = date.toISOString().substring(0,10);

    const submitHandler = () => {
        let uploadRosterResponse;
        setOpenModal(true);
        setResponseMessage(UPLOAD_ROSTER_PROCESSING_MESSAGE);
    
        (async () => {
            try {
                let finalShiftType;
                if(newShiftType) {
                    finalShiftType = newShiftType.toUpperCase();
                }else {
                    finalShiftType = shiftType;
                }
                //shift id has been decided to be constant for upload roster feature
                const formattedShiftID = nodeId+":"+shiftDate+" "+"/"+" "+finalShiftType+" "+"["+SHIFTID+"]";
                uploadRosterResponse = (
                    await AdminApi.uploadRoster(nodeId, selectedFile, finalShiftType, formattedShiftID, shiftDate, username)
                ).data as unknown;
                setResponseMessage(uploadRosterResponse);
            } catch(ex) {
                if (ex.response && ex.response.status === 400) {
                    console.log("400 error message:"+ex.response.data);
                    setResponseMessage(ex.response.data);
                } else if (ex.response && ex.response.status === 403) {
                    console.log("error message:"+ex.response.data);
                    setResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
                } else {
                    console.log("error message:"+ex.response.data);
                    setResponseMessage(UPLOAD_ROSTER_SUBMIT_ERROR_MESSAGE);
                }
            }
        })();
    }
    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
      }, [nodeId]);
    
    const onConfirmClose = useCallback(() => setOpenModal(false), []);

    return(
        <ContentLayout nodeId={nodeId} helperText="">
            <UploadRosterSubmitModal
                open={openModal}
                responseMessage={responseMessage}
                onClose={onConfirmClose}
            />
            <Heading level={2}>Upload Roster Page</Heading>
            <label className="textColor">Please upload the roster csv file with BadgeId, EmployeeName, EmployeeLogin, EmployeeID, AssignedRole fields</label>
            <FileInput
                type={"single"}
                accept=".csv"
                onFileAttached={(acceptedFiles) => {
                  acceptedFiles.forEach((file) => {
                    console.log("Filename", file.name);
                    const fileReader = new FileReader();
                    fileReader.readAsText(file);
                    fileReader.onload = function () {
                        const arrayBuffer: any = fileReader.result;
                        setSelectedFile(arrayBuffer);
                        setReady(true);
                    };
                    file.percentage = 100;
                  });
                  setFiles([...files, ...acceptedFiles]);
                }}
                uploadButtonSize="large"
                uploadButtonLabel={"Browse"}
                showDropZone={true}
                dropZoneLabel="Drag and drop, or click to browse"
                dropZoneDirection="column"
                uploadButtonType={"link"}
              >
                {files.map((file) => {
                  return (
                    <FileDetails
                        error={file.error}
                        errorMessage={file.errorMessage}
                        file={file}
                        connectionSpeed="5MB/s"
                        uploadPercentage={file.percentage}
                        key={file.name}
                        cancelUploadButtonLabel={`Cancel, ${file.name}`}
                        onClickCancel={() => {
                        file.cancel();
                        const updatedFileArr = files.filter(
                            (fl) => fl.name !== file.name
                        );
                        setFiles(updatedFileArr);
                        }}
                        removeFileButtonLabel={`Delete, ${file.name}`}
                        onClickRemoveFile={() => {
                        const updatedFileArr = files.filter(
                            (fl) => fl.name !== file.name
                        );
                        setFiles(updatedFileArr);
                        }}
                        uploadComplete={file.percentage === 100}
                    />
                  );
                })}
              </FileInput>
            <Table>
                <TableRow>
                    <TableCell>Select Shift Type</TableCell>
                    <TableCell>
                        <Select
                            value={shiftType}
                            onChange={setShiftType}
                            placeholder="select shift type..."
                            width={300}
                        >
                            <SelectOption value="DAY" label="DAY" />
                            <SelectOption value="NIGHT" label="NIGHT" />
                            <SelectOption value="TWI" label="TWI" />
                            <SelectOption value="Create new" label="Create new" />
                        </Select>
                    </TableCell>
                </TableRow>
                {(() => {
                if(shiftType === "Create new")
                {return(
                    <TableRow>
                        <TableCell>New Shift Type</TableCell>
                        <TableCell>
                            <Input value={newShiftType} width={300} size="small" placeholder="Enter shift type" onChange={setNewShiftType}/>
                        </TableCell>
                    </TableRow> 
                )
                }
                })()}
                </Table>
                <Row>
                    <Button type="primary" size="medium" disabled={!files[0]} submit={ready} onClick={submitHandler}>Submit</Button>
                </Row>
        </ContentLayout>
    );
}
