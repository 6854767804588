import {Route, Switch} from "react-router-dom";
import Homepage from "../home";
import AddRolesPage from "../configurationPage/addRoles/AddRolesPage";
import CalmCodePage from "../configurationPage/calmCode/CalmCodePage";
import UploadRosterPage from "../configurationPage/uploadRoster/UploadRosterPage";
import ManualLaborTrackPage from "../manualLaborTrack/ManualLaborTrackPage";
import AuditPage from "../audit/AuditPage";
import {ManualLaborTrackContextProvider} from "../../store/manual-labor-track-store";
import {LHQTableContextProvider} from "../../store/lhq-store";
import {AuditContextProvider} from "../../store/audit-store";
import {LMSTableContextProvider} from "../../store/lms-store";
import AddCertificatesPage from "../configurationPage/addCertificates/AddCertificatePage";
import {LMSManualLaborTrackContextProvider} from "../../store/lms-manual-labor-track-store";
import LMSManualLaborTrackPage from "../lmsManualLaborTrack/LMSManualLaborTrackPage";

export default function Routes() {
    return (
        <Switch>
            <Route exact path={["/", "/:nodeId"]}>
                <Homepage/>
            </Route>
            <Route exact path="/:nodeId/config/calm-code" component={CalmCodePage}/>
            <Route exact path="/:nodeId/config/upload-roster" component={UploadRosterPage}/>
            {/*<Route exact path="/:nodeId/config/add-roles">
                <LHQTableContextProvider>
                    <AddRolesPage/>
                </LHQTableContextProvider>
            </Route>*/}
            <Route exact path="/:nodeId/config/add-certificates">
                <LMSTableContextProvider>
                    <AddCertificatesPage/>
                </LMSTableContextProvider>
            </Route>
            {/*<Route exact
                   path="/:nodeId/manual-labor-track"
            >
                <ManualLaborTrackContextProvider>
                    <ManualLaborTrackPage/>
                </ManualLaborTrackContextProvider>
            </Route>*/}
            <Route exact
                   path="/:nodeId/lms-manual-labor-track"
            >
                <LMSManualLaborTrackContextProvider>
                    <LMSManualLaborTrackPage/>
                </LMSManualLaborTrackContextProvider>
            </Route>
            <Route exact path="/:nodeId/audit">
                <AuditContextProvider>
                    <AuditPage/>
                </AuditContextProvider>
            </Route>
            <Route path="/" component={Homepage}/>
        </Switch>
    );
}
