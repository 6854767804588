import React, { useEffect, useState, useCallback } from "react";
import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import CalmCodeSubmitModal from "../../modal/CalmCodeSubmitModal";
import {
  DEFAULT_SORT_COLUMN,
  DEFAULT_SORT_DIRECTION,
  DELAY_IN_MINUTES_TOOLTIP_TEXT,
  CALM_CODE_PROCESSING_MESSAGE,
  CALM_CODE_SUBMIT_ERROR_MESSAGE,
  ROLE_NAME_LABEL,
  ROLE_NAME_FIELD_NAME,
  CALM_CODE_LABEL,
  CALM_CODE_FIELD_NAME,
  DELAY_IN_MIN_LABEL,
  DELAY_IN_MIN_FIELD_NAME, ALERT_AUTHENTICATION_MESSAGE,
} from "../../../constants";
import AdminApiFactory from "../../../admin-api/AdminApiFactory";
import orderBy from "lodash/orderBy";

const CALM_CODE_TABLE_TITLES_EUDOMAIN = [
  { headerName: ROLE_NAME_LABEL, sortId: ROLE_NAME_FIELD_NAME },
  { headerName: CALM_CODE_LABEL, sortId: CALM_CODE_FIELD_NAME },
  {
    headerName: DELAY_IN_MIN_LABEL,
    sortId: DELAY_IN_MIN_FIELD_NAME,
  },
];

export default function CalmCodeTableEUDomain({ sargRoleList }) {
  const [userSargRoles, setUserSargRoles] = useState(sargRoleList);
  const [roleNameFilter, setRoleNameFilter] = useState("");
  const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(
    DEFAULT_SORT_DIRECTION
  );
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState<any>();

  const AdminApi = AdminApiFactory();

  useEffect(() => {
    setUserSargRoles(sargRoleList);

    return () => setUserSargRoles([]);
  }, [sargRoleList]);

  const getUniqueKey = (sargRoleId, fieldName) => {
    return `${sargRoleId}-${fieldName}`;
  };

  const calmCodeChangeHandler = (sargRoleUniqueId) => (inputCalmCode) => {
    setUserSargRoles(
      userSargRoles.map((role) =>
        role.sargRoleUniqueId === sargRoleUniqueId
          ? { ...role, calmCode: inputCalmCode }
          : role
      )
    );
  };

  const delayInMinutesChangeHandler =
    (sargRoleUniqueId) => (inputDelayInMinutes) => {
      const inputDelayInMinutesToNumber = parseInt(inputDelayInMinutes) || 0;
      setUserSargRoles(
        userSargRoles.map((role) =>
          role.sargRoleUniqueId === sargRoleUniqueId
            ? { ...role, delayInMinutes: inputDelayInMinutesToNumber }
            : role
        )
      );
    };

  const submitHandler = () => {
    if (userSargRoles && userSargRoles.length) {
      setOpenModal(true);
      setResponseMessage(CALM_CODE_PROCESSING_MESSAGE);
      const { nodeId } = userSargRoles[0];
      (async () => {
        try {
          const updateCalmCodeResponse = (
            await AdminApi.updateCalmCodeMapping(nodeId, userSargRoles)
          ).data as unknown;
          // setResponseMessage will be processed later inside Modal component
          setResponseMessage(updateCalmCodeResponse);
        } catch (ex) {
          console.error(
            `Error posting calm code to /admin/sargRole/${nodeId}`,
            ex
          );
          if (ex.response && ex.response.status === 403) {
            setResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
          } else {
            setResponseMessage(CALM_CODE_SUBMIT_ERROR_MESSAGE);
          }
        }
      })();
    }
  };

  const onSortHandler = (event) => {
    setUserSargRoles(
      orderBy(
        userSargRoles,
        [event.sortColumn],
        [event.sortDirection === "ascending" ? "asc" : "desc"]
      )
    );
    setSortColumn(event.sortColumn);
    setSortDirection(event.sortDirection);
  };

  const onConfirmClose = useCallback(() => setOpenModal(false), []);

  return (
    <Column>
      <CalmCodeSubmitModal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      <Table
        headerRows={1}
        showDividers
        fixHeaderRows
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSortHandler}
      >
        <TableActionBar>
          <Input
            key="searchInput"
            type="text"
            value={roleNameFilter}
            placeholder="Filter by Role Name"
            prefixIconTokens={filterTokenIcon}
            onChange={setRoleNameFilter}
          />
        </TableActionBar>
        <TableRow key="calmCodeTitle">
          {CALM_CODE_TABLE_TITLES_EUDOMAIN.map(({ headerName, sortId }, index) => {
            if (index === CALM_CODE_TABLE_TITLES_EUDOMAIN.length - 1) {
              return (
                <TableCell key={headerName} header={true} sortColumn={sortId}>
                  <Tooltip
                    key={`${headerName}-tooltip`}
                    position="top"
                    title={DELAY_IN_MINUTES_TOOLTIP_TEXT}
                  >
                    <Text type="h100">
                      {headerName + " "}
                      <Icon tokens={helpKnockoutTokens} />
                    </Text>
                  </Tooltip>
                </TableCell>
              );
            } else {
              return (
                <TableCell key={headerName} sortColumn={sortId}>
                  {headerName}
                </TableCell>
              );
            }
          })}
        </TableRow>
        {userSargRoles
          .filter(({ roleName }) =>
            roleNameFilter ? roleName.toLowerCase().includes(roleNameFilter.toLocaleLowerCase()) : true
          )
          .map(
            ({
              roleName,
              calmCode,
              delayInMinutes,
              sargRoleUniqueId,
            }) => (
              <TableRow key={getUniqueKey(sargRoleUniqueId, "row")}>
                <TableCell
                  key={getUniqueKey(sargRoleUniqueId, `roleName-${roleName}`)}
                >
                  {roleName}
                </TableCell>
                <TableCell key={getUniqueKey(sargRoleUniqueId, "calmCode")}>
                  <Input
                    type="text"
                    value={calmCode}
                    onChange={calmCodeChangeHandler(sargRoleUniqueId)}
                  />
                </TableCell>
                <TableCell
                  key={getUniqueKey(sargRoleUniqueId, "delayInMinutes")}
                >
                  <Input
                    type="number"
                    value={delayInMinutes}
                    onChange={delayInMinutesChangeHandler(sargRoleUniqueId)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
      </Table>
      <Row alignmentHorizontal="right">
        <Button onClick={submitHandler}>Submit</Button>
      </Row>
    </Column>
  );
}
