import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";
import homeIconTokens from "@amzn/meridian-tokens/base/icon/home";
import cogIconTokens from "@amzn/meridian-tokens/base/icon/cog";
import dashboardIconTokens from "@amzn/meridian-tokens/base/icon/dashboard";
import clockIconTokens from "@amzn/meridian-tokens/base/icon/clock";
import {
  HOME_LINK_LABEL,
  CONFIGURATION_LINK_LABEL,
  CONFIGURATION_CALM_CODE_LABEL,
  CONFIGURATION_ADD_ROLES_LABEL,
  MANUAL_LABOR_TRACK_LINK_LABEL,
  SIDE_MENU_HASH_KEY,
  SIDE_MENU_OPEN_HASH_KEY,
  AUDIT_LINK_LABEL, CONFIGURATION_ADD_CERTIFICATE_LABEL, LMS_MANUAL_LABOR_TRACK_LINK_LABEL,
} from "../../constants";
import AdminContext from "../../store/admin-context";

const NAVLINK_HASH = {
  home: "#home",
  configuration: "#configuration",
  calmCode: "#calmCode",
  addRoles: "#addRoles",
  manualLaborTrack: "#manualLaborTrack",
  audit: "#audit",
};

const NAVLINK_HASH_LMS = {
  home: "#home",
  configuration: "#configuration",
  calmCode: "#calmCode",
  addCertificates: "#addCertificates",
  lmsManualLaborTrack: "#lmsManualLaborTrack",
  audit: "#audit",
};

const NAVLINK_HASH_AIR = {
  home: "#home",
  configuration: "#configuration",
  calmCode: "#calmCode",
  audit: "#audit",
};

const NAVLINK_HASH_OBJECTS = [
  {
    hashName: NAVLINK_HASH.home,
    label: HOME_LINK_LABEL,
    iconTokens: homeIconTokens,
    isNested: false,
    hrefLink: "/",
  },
  {
    hashName: NAVLINK_HASH.configuration,
    label: CONFIGURATION_LINK_LABEL,
    iconTokens: cogIconTokens,
    isNested: true,
    hrefLink: "/config",
    nestedLinks: [
      {
        hashName: NAVLINK_HASH.calmCode,
        label: CONFIGURATION_CALM_CODE_LABEL,
        hrefLink: "/calm-code",
      },
      {
        hashName: NAVLINK_HASH.addRoles,
        label: CONFIGURATION_ADD_ROLES_LABEL,
        hrefLink: "/add-roles",
      }
    ]
  },
  {
    hashName: NAVLINK_HASH.manualLaborTrack,
    label: MANUAL_LABOR_TRACK_LINK_LABEL,
    iconTokens: dashboardIconTokens,
    isNested: false,
    hrefLink: "/manual-labor-track"
  },
  {
    hashName: NAVLINK_HASH.audit,
    label: AUDIT_LINK_LABEL,
    iconTokens: clockIconTokens,
    isNested: false,
    hrefLink: "/audit"
  }
];

const NAVLINK_HASH_OBJECTS_AIR = [
  {
    hashName: NAVLINK_HASH.home,
    label: HOME_LINK_LABEL,
    iconTokens: homeIconTokens,
    isNested: false,
    hrefLink: "/",
  },
  {
    hashName: NAVLINK_HASH.configuration,
    label: CONFIGURATION_LINK_LABEL,
    iconTokens: cogIconTokens,
    isNested: true,
    hrefLink: "/config",
    nestedLinks: [
      {
        hashName: NAVLINK_HASH.calmCode,
        label: CONFIGURATION_CALM_CODE_LABEL,
        hrefLink: "/calm-code",
      }
    ]
  },
  {
    hashName: NAVLINK_HASH.audit,
    label: AUDIT_LINK_LABEL,
    iconTokens: clockIconTokens,
    isNested: false,
    hrefLink: "/audit"
  }
];

const NAVLINK_HASH_OBJECTS_LMS = [
  {
    hashName: NAVLINK_HASH_LMS.home,
    label: HOME_LINK_LABEL,
    iconTokens: homeIconTokens,
    isNested: false,
    hrefLink: "/",
  },
  {
    hashName: NAVLINK_HASH_LMS.configuration,
    label: CONFIGURATION_LINK_LABEL,
    iconTokens: cogIconTokens,
    isNested: true,
    hrefLink: "/config",
    nestedLinks: [
      {
        hashName: NAVLINK_HASH_LMS.calmCode,
        label: CONFIGURATION_CALM_CODE_LABEL,
        hrefLink: "/calm-code",
      },
      {
        hashName: NAVLINK_HASH_LMS.addCertificates,
        label: CONFIGURATION_ADD_CERTIFICATE_LABEL,
        hrefLink: "/add-certificates",
      }
    ]
  },
  {
    hashName: NAVLINK_HASH_LMS.lmsManualLaborTrack,
    label: LMS_MANUAL_LABOR_TRACK_LINK_LABEL,
    iconTokens: dashboardIconTokens,
    isNested: false,
    hrefLink: "/lms-manual-labor-track"
  },
  {
    hashName: NAVLINK_HASH_LMS.audit,
    label: AUDIT_LINK_LABEL,
    iconTokens: clockIconTokens,
    isNested: false,
    hrefLink: "/audit"
  }
];

const PATH_NAMES_HASH_ARRAY = [{
  hrefLink: "/audit",
  hashName: NAVLINK_HASH.audit,
},
  {
  hrefLink: "/manual-labor-track",
  hashName: NAVLINK_HASH.manualLaborTrack,
},
  {
  hrefLink: "/add-roles",
  hashName: NAVLINK_HASH.addRoles,
},
  {
  hrefLink: "/calm-code",
  hashName: NAVLINK_HASH.calmCode,
},
];

const PATH_NAMES_HASH_ARRAY_LMS = [{
  hrefLink: "/audit",
  hashName: NAVLINK_HASH_LMS.audit,
},

  {
    hrefLink: "/lms-manual-labor-track",
    hashName: NAVLINK_HASH_LMS.lmsManualLaborTrack,
  },
  {
    hrefLink: "/add-certificates",
    hashName: NAVLINK_HASH_LMS.addCertificates,
  },
  {
    hrefLink: "/calm-code",
    hashName: NAVLINK_HASH_LMS.calmCode,
  },
];

const PATH_NAMES_HASH_ARRAY_AIR = [{
  hrefLink: "/audit",
  hashName: NAVLINK_HASH.audit,
},
  {
    hrefLink: "/calm-code",
    hashName: NAVLINK_HASH.calmCode,
  },
];

export default function AdminSideMenu() {
  const location = useLocation();
  const history = useHistory();
  const [hash, setHash] = useState(getDefaultHash(location));
  const [openLinkHash, setOpenLinkHash] = useState(localStorage.getItem(SIDE_MENU_OPEN_HASH_KEY) || null);

  function getDefaultHash(location) {
    let localStoredHash = localStorage.getItem(SIDE_MENU_HASH_KEY);
    let locationHash = getLocationHashFromPath(location.pathname);
    if (localStoredHash === locationHash) {
      return localStoredHash;
    } else {
      localStorage.setItem(SIDE_MENU_HASH_KEY, locationHash);
      return locationHash;
    }
  }

  function getLocationHashFromPath(pathname) {
    for (let linkItem of PATH_NAMES_HASH_ARRAY) {
      if (pathname.includes(linkItem.hrefLink)) {
        return linkItem.hashName;
      }
    }
    for (let linkItem of PATH_NAMES_HASH_ARRAY_LMS) {
      if (pathname.includes(linkItem.hrefLink)) {
        return linkItem.hashName;
      }
    }

    for (let linkItem of PATH_NAMES_HASH_ARRAY_AIR) {
      if (pathname.includes(linkItem.hrefLink)) {
        return linkItem.hashName;
      }
    }
    return NAVLINK_HASH.home;
  }


  const adminContext = useContext(AdminContext);
  const { selectedNode, isSideBarOpen } = adminContext;
  var airNodes = [ 'KILN', 'KLAL', 'KCVG', 'KRFD', 'KAFW', 'KBWI', 'KRIV', 'KSBD' ];
  var lmsNodes = ['LMS1', 'CLT9', 'BNA5', 'BWI5', 'DAL9', 'DEN5', 'DTW5', 'DTW8', 'EWR5', 'JAX9',
  'MCI5', 'MCO5', 'MDW5', 'MGE5', 'MSP9', 'ONT5' , 'PNE5', 'RBD5', 'RSW5', 'TCY9', 'YYC5', 'ABQ5', 'AFW5', 'AZA5',
  'AZA9', 'BDU5', 'BOI5', 'BOS4', 'BUF5', 'COS5', 'DFW8', 'DSM9', 'IAH5', 'LBB5', 'MCI9', 'MCO9', 'MIA2', 'MIA5',
  'MSP7', 'MSP8', 'OKC5', 'SLC9', 'STL5', 'STL9', 'TUL5', 'TUS5', 'VGT5', 'ATL6', 'AUS5', 'BNA8', 'BNA9', 'BTR9',
  'CLE5', 'CMH5', 'CVG5', 'CVG9', 'DTW9', 'HOU1', 'HOU5', 'JAX5', 'MEM5', 'MGE8', 'MGE9', 'MKE5', 'MKE6', 'MQJ5',
  'ORD9', 'PDX5', 'PIT5', 'PIT9', 'RNT9', 'SAT5', 'ACY5', 'ACY8', 'ACY9', 'AGS5', 'BDL5', 'BOS5', 'CDW5', 'CLT5',
  'HFD5', 'LAX5', 'LDJ5', 'LGA5', 'LPR5', 'MDT5', 'MOB5', 'MTN2', 'MTN5', 'MTN6', 'MTN9', 'OWD5', 'OWD9', 'RDU5',
  'RDU9', 'RIC5', 'SAT9', 'SCK9', 'BFI5', 'BFI8', 'CNO5', 'EUG5', 'EWR8', 'LGB5', 'MSP5', 'OAK5', 'ONT1', 'SAN5',
  'SMF5', 'TCY5', 'YHM5', 'YHM9', 'YUL5', 'YUL9', 'YVR7'];


  // LMS1 would be the test node for LMS migration
  if (!lmsNodes.includes(selectedNode)) {
    //Manual Labor Tracking needs to be disabled for AIR Nodes
    if (airNodes.includes(selectedNode)) {
      return (
          <SideMenu open={isSideBarOpen}>
            {NAVLINK_HASH_OBJECTS_AIR.map(({isNested, hashName, label, iconTokens, hrefLink, nestedLinks = []}) => {
              if (isNested) {
                return <SideMenuLink
                    key={hashName}
                    href={hashName}
                    selected={hash === hashName}
                    open={openLinkHash === hashName}
                    onClick={(hashValue) => {
                      setHash(hashValue);
                      localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                      let nextOpenLinkHash = openLinkHash === hashName ? null : hashValue;
                      setOpenLinkHash(nextOpenLinkHash);
                      localStorage.setItem(SIDE_MENU_OPEN_HASH_KEY, nextOpenLinkHash);
                    }}
                    disabled={!selectedNode}
                    icon={iconTokens}
                >
                  {label}
                  {nestedLinks.map((nestedLink) => (
                      <SideMenuLink
                          key={nestedLink.hashName}
                          href={nestedLink.hashName}
                          selected={hash === nestedLink.hashName}
                          disabled={!selectedNode}
                          onClick={(hashValue) => {
                            setHash(hashValue);
                            localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                            history.push(`/${selectedNode}${hrefLink}${nestedLink.hrefLink}`);
                          }}
                      >
                        {nestedLink.label}
                      </SideMenuLink>
                  ))}
                </SideMenuLink>
              } else {
                return <SideMenuLink
                    key={hashName}
                    href={hashName}
                    selected={hash === hashName}
                    onClick={(hashValue) => {
                      setHash(hashValue);
                      localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                      history.push(selectedNode ? `/${selectedNode}${hrefLink}` : "/");
                    }}
                    disabled={hashName === NAVLINK_HASH_AIR.home ? false : !selectedNode}
                    icon={iconTokens}
                >{label}</SideMenuLink>
              }
            })}
          </SideMenu>
      );

    }
    else {
      return (
          <SideMenu open={isSideBarOpen}>
            {NAVLINK_HASH_OBJECTS.map(({isNested, hashName, label, iconTokens, hrefLink, nestedLinks = []}) => {
              if (isNested) {
                return <SideMenuLink
                    key={hashName}
                    href={hashName}
                    selected={hash === hashName}
                    open={openLinkHash === hashName}
                    onClick={(hashValue) => {
                      setHash(hashValue);
                      localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                      let nextOpenLinkHash = openLinkHash === hashName ? null : hashValue;
                      setOpenLinkHash(nextOpenLinkHash);
                      localStorage.setItem(SIDE_MENU_OPEN_HASH_KEY, nextOpenLinkHash);
                    }}
                    disabled={!selectedNode}
                    icon={iconTokens}
                >
                  {label}
                  {nestedLinks.map((nestedLink) => (
                      <SideMenuLink
                          key={nestedLink.hashName}
                          href={nestedLink.hashName}
                          selected={hash === nestedLink.hashName}
                          disabled={!selectedNode}
                          onClick={(hashValue) => {
                            setHash(hashValue);
                            localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                            history.push(`/${selectedNode}${hrefLink}${nestedLink.hrefLink}`);
                          }}
                      >
                        {nestedLink.label}
                      </SideMenuLink>
                  ))}
                </SideMenuLink>
              } else {
                return <SideMenuLink
                    key={hashName}
                    href={hashName}
                    selected={hash === hashName}
                    onClick={(hashValue) => {
                      setHash(hashValue);
                      localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                      history.push(selectedNode ? `/${selectedNode}${hrefLink}` : "/");
                    }}
                    disabled={hashName === NAVLINK_HASH.home ? false : !selectedNode}
                    icon={iconTokens}
                >{label}</SideMenuLink>
              }
            })}
          </SideMenu>
      );
    }
  }
  else {
    return (
        <SideMenu open={isSideBarOpen}>
          {NAVLINK_HASH_OBJECTS_LMS.map(({isNested, hashName, label, iconTokens, hrefLink, nestedLinks = []}) => {
            if (isNested) {
              return <SideMenuLink
                  key={hashName}
                  href={hashName}
                  selected={hash === hashName}
                  open={openLinkHash === hashName}
                  onClick={(hashValue) => {
                    setHash(hashValue);
                    localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                    let nextOpenLinkHash = openLinkHash === hashName ? null : hashValue;
                    setOpenLinkHash(nextOpenLinkHash);
                    localStorage.setItem(SIDE_MENU_OPEN_HASH_KEY, nextOpenLinkHash);
                  }}
                  disabled={!selectedNode}
                  icon={iconTokens}
              >
                {label}
                {nestedLinks.map((nestedLink) => (
                    <SideMenuLink
                        key={nestedLink.hashName}
                        href={nestedLink.hashName}
                        selected={hash === nestedLink.hashName}
                        disabled={!selectedNode}
                        onClick={(hashValue) => {
                          setHash(hashValue);
                          localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                          history.push(`/${selectedNode}${hrefLink}${nestedLink.hrefLink}`);
                        }}
                    >
                      {nestedLink.label}
                    </SideMenuLink>
                ))}
              </SideMenuLink>
            } else {
              return <SideMenuLink
                  key={hashName}
                  href={hashName}
                  selected={hash === hashName}
                  onClick={(hashValue) => {
                    setHash(hashValue);
                    localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                    history.push(selectedNode ? `/${selectedNode}${hrefLink}` : "/");
                  }}
                  disabled={hashName === NAVLINK_HASH_LMS.home ? false : !selectedNode}
                  icon={iconTokens}
              >{label}</SideMenuLink>
            }
          })}
        </SideMenu>
    );
  }
}
