// Screen layout
export const WELCOME_MESSAGE = "Welcome to FastStart Admin!";
export const ATSQUAD_WIKI_LINK = "https://w.amazon.com/bin/view/NASC/ATSquad";
export const FASTSTART_WIKI_LINK = "https://w.amazon.com/bin/view/Biz_Transformation_&_Tech_Products/Fast_Start_Program/NAWS_Migration";
export const TECHNICAL_ISSUE_LINK = "https://issues.amazon.com/issues/create?assignedFolder=a4415a38-8bca-4f6e-957d-a97379825bf5";
export const FEATURE_REQUEST_LINK = "https://form.asana.com/?k=WMbLtMLiv02N9OlbAxL0MA&d=8442528107068";
export const PERMISSIONS_REQUEST_LINK = "https://permissions.amazon.com/group.mhtml?group=FastStart-Admins&group_type=ldap&";
export const HOME_LINK_LABEL = "Home";
export const CONFIGURATION_LINK_LABEL = "Configuration";
export const CONFIGURATION_CALM_CODE_LABEL = "Roster Table";
export const CONFIGURATION_ADD_ROLES_LABEL = "LHQ Table";
export const CONFIGURATION_ADD_CERTIFICATE_LABEL = "LMS Table";
export const CONFIGURATION_UPLOAD_ROSTER_LABEL = "Upload Roster";
export const MANUAL_LABOR_TRACK_LINK_LABEL = "Manual Labor Track";
export const LMS_MANUAL_LABOR_TRACK_LINK_LABEL = "LMS Manual Labor Track";
export const AUDIT_LINK_LABEL = "Audit";
export const ALERT_DEFAULT_MESSAGE =
  "Something went wrong loading admin info. Please try again.";
export const ALERT_AUTHENTICATION_MESSAGE =
  "You are not authorized to access this site. Please request access(FastStart-Admins LDAP) from Amazon permissions website.";
// localStorage keys
export const USER_NAME_KEY = "username";
export const SIDE_MENU_HASH_KEY = "sideMenuHash";
export const SIDE_MENU_OPEN_HASH_KEY = "sideMenuOpenHash";

// NodeId dropdown
export const NODE_ID_LABEL_TEXT = "Please select Node to continue*";
export const NODE_ID_DROPDOWN_PLACEHOLDER = "- Node ID -";
export const NODE_LIST_KEY = "nodeList";

// Roster Table page - CalmCodePage
export const DEFAULT_SORT_COLUMN = "calmCode";
export const DEFAULT_SORT_DIRECTION = "descending";
export const CALM_CODE_DISPLAY_TEXT =
  "Please review your changes and click submit. Click Edit to make more changes or, Go Back to go to the previous page.";
export const DELAY_IN_MINUTES_TOOLTIP_TEXT =
  "Configure the time delay to begin labor tracking after associate scans badge through FastStart kiosk.";
export const CALM_CODE_PROCESSING_MESSAGE = "Processing...";
export const CALM_CODE_SUBMIT_SUCCESS_STATUS = "success";
export const CALM_CODE_SUBMIT_ERROR_MESSAGE = "Please try again.";
export const DELAY_IN_MIN_LABEL = "Delay Labor Tracking Timer (in mins)";
export const CALM_CODE_LABEL = "Calm Code";
export const ROLE_NAME_LABEL = "Role Name";
export const CERTIFICATE_NAME_LABEL = "Certificate Name";
export const DELAY_IN_MIN_FIELD_NAME = "delayInMinutes";
export const CALM_CODE_FIELD_NAME = "calmCode";
export const ROLE_NAME_FIELD_NAME = "roleName";
export const CERTIFICATE_NAME_FIELD_NAME = "certificateName";

// LHQ Table page - AddRolesPage
export const ADD_ROLES_DISPLAY_TEXT =
  "Please Fill out the form to Add new Role, then click Add button.";

// Manual Labor Track page
export const SITE_SPECIFIC_TYPE = "siteSpecific";
export const LHQ_TYPE = "LHQ";
export const LMS_TYPE = "LMS";
export const NO_CALM_CODE_TYPE = "noCalmCode";
export const COLUMN_TYPES = [SITE_SPECIFIC_TYPE, LHQ_TYPE, NO_CALM_CODE_TYPE];
export const LMS_COLUMN_TYPES = [SITE_SPECIFIC_TYPE, LMS_TYPE, NO_CALM_CODE_TYPE];
export const NO_CALM_CODE_TOOLTIP = "FastStart cannot perform “Manual Labor Tracking” for the below Roles since the associated Calm Codes are missing. To labor track, please configure Calm Code under “Configuration”.";
export const TOASTER_TIMEOUT_OFFSET = 2000; // 2s

// Audit Page
export const EMPLOYEE_BADGE_URL = "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=";
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const DEFAULT_COUNTDOWN_IN_SECONDS = 5 * 60;  // five minutes
export const TIMESTAMP_FIELDNAME = "timestamp";
export const ASSOCIATE_ALIAS_FIELDNAME = "associateAlias";
export const ASSIGNED_ROLE_FIELDNAME = "assignedRole";
export const TRAINED_ROLES_FIELDNAME = "trainedRoles";
export const BADGE_IN_STATUS_FIELDNAME = "badgeScanStatus";
export const LABOR_TRACKED_FIELDNAME = "laborTracked";
export const LABOR_TRACK_TYPE_FIELDNAME = "associateLaborTrackType";
export const MANAGER_ALIAS_FIELDNAME = "operatorAlias";
export const CALM_CODE_FIELDNAME = "calmCode";
export const INPUT_TYPE = "input";
export const DROPDOWN_TYPE = "dropdown";
export const AUDIT_TABLE_OBJECT_LIST = [{
  title: "Timestamp",
  fieldName: TIMESTAMP_FIELDNAME,
  fieldType: INPUT_TYPE,
}, {
  title: "Associate Alias",
  fieldName: ASSOCIATE_ALIAS_FIELDNAME,
  fieldType: INPUT_TYPE,
}, {
  title: "Assigned Role",
  fieldName: ASSIGNED_ROLE_FIELDNAME,
  fieldType: DROPDOWN_TYPE,
}, {
  title: "Trained Roles",
  fieldName: TRAINED_ROLES_FIELDNAME,
  fieldType: DROPDOWN_TYPE,
}, {
  title: "Badge In Status",
  fieldName: BADGE_IN_STATUS_FIELDNAME,
  fieldType: DROPDOWN_TYPE,
}, {
  title: "Labor Tracked",
  fieldName: LABOR_TRACKED_FIELDNAME,
  fieldType: DROPDOWN_TYPE,
}, {
  title: "Labor Track Type",
  fieldName: LABOR_TRACK_TYPE_FIELDNAME,
  fieldType: DROPDOWN_TYPE,
}, {
  title: "Manager Alias",
  fieldName: MANAGER_ALIAS_FIELDNAME,
  fieldType: DROPDOWN_TYPE,
}, {
  title: "Calm Code",
  fieldName: CALM_CODE_FIELDNAME,
  fieldType: DROPDOWN_TYPE,
}];

//Upload Roster Page
export const EUROPE_DOMAINNAME = "eu-west-1";
export const UPLOAD_ROSTER_PROCESSING_MESSAGE = "Processing...";
export const UPLOAD_ROSTER_SUBMIT_SUCCESS_STATUS = "success";
export const UPLOAD_ROSTER_SUBMIT_ERROR_MESSAGE = "Please try again.";
//shift id has been decided to be constant for upload roster feature,
export const SHIFTID = "V1";
