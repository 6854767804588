import React from "react";
import Alert from "@amzn/meridian/alert";
import { AlertProps, AlertType } from "@amzn/meridian/alert/alert";
import Toaster from "@amzn/meridian/toaster";

export default function LMSManualLaborTrackMessageToast({
  message,
  toasts,
  onCloseToast,
}) {
  let type: AlertType = message.indexOf("success") > -1 ? "success" : "error";
  return (
    <Toaster toasts={toasts} onCloseToast={onCloseToast}>
      {({ onClose }) => {
        let alertProp: AlertProps = {
          toast: true,
          type: type,
          children: []
        }
        if (type === "error") {
          alertProp.onClose = onClose;
        }
        return <Alert
          {...alertProp}
        >
          {message}
        </Alert>
      }}
    </Toaster>
  );
}