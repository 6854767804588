import React, { useCallback, useContext, useState } from "react";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import {
    CALM_CODE_LABEL,
    CALM_CODE_SUBMIT_SUCCESS_STATUS,
    DELAY_IN_MINUTES_TOOLTIP_TEXT,
    DELAY_IN_MIN_LABEL,
    CERTIFICATE_NAME_LABEL,
    TOASTER_TIMEOUT_OFFSET, ALERT_AUTHENTICATION_MESSAGE,
} from "../../../constants";
import AddRolesModal from "../../modal/AddRolesModal";
import AddRolesMessageToast from "../addRoles/AddRolesMessageToast";
import LMSTableContext from "../../../store/lms-store";
import AdminApiFactory from "../../../admin-api/AdminApiFactory";
import { v4 as uuid } from 'uuid'


let dummyId = 0;

export default function AddCertificatesForm({ nodeId }) {
    const LmsTableContext = useContext(LMSTableContext);
    const { reloadLmsList, setReloadLmsList, lmsCertificateList } = LmsTableContext;
    const [userCertificateName, setUserCertificateName] = useState("");
    const [isCertificateNameValid, setIsCertificateNameValid] = useState(true);
    const [certificateNameErrorMessage, setCertificateNameErrorMessage] = useState("");
    const [userCalmCode, setUserCalmCode] = useState("");
    const [isCalmCodeValid, setIsCalmCodeValid] = useState(true);
    const [calmCodeErrorMessage, setCalmCodeErrorMessage] = useState("");
    const [userDelayInMinutes, setUserDelayInMinutes] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [toasts, setToasts] = useState<{ id: string, timeout: number }[]>([]);
    const [addCertificatesResponseMessage, setAddCertificatesResponseMessage] =
        useState<any>("");
    const lmsCertificateNameList = lmsCertificateList.map(item => item.certificateName) || [""];

    const AdminApi = AdminApiFactory();

    const addCertificatesHandler = () => {
        // Certificate Name frontend validation
        if (userCertificateName && !lmsCertificateNameList.includes(userCertificateName)) {
            setIsCertificateNameValid(true);
            setCertificateNameErrorMessage("");
        } else if (!userCertificateName) {
            setIsCertificateNameValid(false);
            setCertificateNameErrorMessage("Certificate Name is required.");
        } else {
            setIsCertificateNameValid(false);
            setCertificateNameErrorMessage("Duplicate Certificate Name.");
        }
        // Calm Code frontend validation
        if (userCalmCode) {
            setIsCalmCodeValid(true);
            setCalmCodeErrorMessage("");
        } else {
            setIsCalmCodeValid(false);
            setCalmCodeErrorMessage("Calm Code is required.");
        }

        if (userCertificateName && !lmsCertificateNameList.includes(userCertificateName) && userCalmCode) {
            setShowConfirmModal(true);
        } else {
            setShowConfirmModal(false);
        }
    };
    const confirmAddCertificatesHandler = () => {
        (async () => {
            try {
                const addCertificatesResponse = (
                    await AdminApi.createLMSCustomRoleForSite(nodeId, {
                        nodeId,
                        certificateName: userCertificateName,
                        certificateId: uuid() ,
                        calmCode: userCalmCode,
                        isLMS: true,
                        delayInMinutes: userDelayInMinutes,
                    })
                ).data as unknown;
                setAddCertificatesResponseMessage(addCertificatesResponse);
                onOpenToast();
                if (addCertificatesResponse === CALM_CODE_SUBMIT_SUCCESS_STATUS) {
                    setReloadLmsList(!reloadLmsList); // flip reloadLmsList flag to trigger LMSTable reload
                }
            } catch (ex) {
                console.error(`Error createCustomCertificateForSite ${nodeId}`, ex);
                if (ex.response && ex.response.status === 403) {
                    setAddCertificatesResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
                    onOpenToast();
                }
            } finally {
                // cleanup
                setShowConfirmModal(false);
                setUserCertificateName("");
                setUserCalmCode("");
                setUserDelayInMinutes(0);
            }
        })();
    };
    const onConfirmClose = useCallback(() => setShowConfirmModal(false), []);
    const onCloseToast = useCallback(
        (id) => setToasts(toasts.filter((t) => t.id !== id)),
        [toasts]
    );
    const onOpenToast = useCallback(
        () => setToasts(toasts.concat({ id: `${++dummyId}`, timeout: TOASTER_TIMEOUT_OFFSET })),
        [toasts]
    );

    return (
        <Column width="80%">
            <AddRolesModal
                open={showConfirmModal}
                onClose={onConfirmClose}
                confirmHandler={confirmAddCertificatesHandler}
            />
            <AddRolesMessageToast
                toasts={toasts}
                onCloseToast={onCloseToast}
                message={addCertificatesResponseMessage}
            />
            <Row>
                <Heading level={2}>Add New Certificate</Heading>
            </Row>
            <Row
                alignmentVertical="top"
                width="100%"
                widths={["grid-4", "grid-4", "grid-4"]}
                minHeight={70}
            >
                <Box>
                    <Text tag="label" type="b200" htmlFor="certificateName">
                        {CERTIFICATE_NAME_LABEL} *
                    </Text>
                    <Input
                        id="certificateName"
                        value={userCertificateName}
                        onChange={setUserCertificateName}
                        error={!isCertificateNameValid}
                        errorMessage={certificateNameErrorMessage}
                    ></Input>
                </Box>
                <Box>
                    <Text tag="label" type="b200" htmlFor="calmCode">
                        {CALM_CODE_LABEL} *
                    </Text>
                    <Input
                        id="calmCode"
                        value={userCalmCode}
                        onChange={setUserCalmCode}
                        error={!isCalmCodeValid}
                        errorMessage={calmCodeErrorMessage}
                    ></Input>
                </Box>
                <Box>
                    <Text tag="label" type="b200" htmlFor="delayInMins">
                        {DELAY_IN_MIN_LABEL}{" "}
                        <Tooltip position="top" title={DELAY_IN_MINUTES_TOOLTIP_TEXT}>
                            <Icon tokens={helpKnockoutTokens} />
                        </Tooltip>
                    </Text>
                    <Input
                        id="delayInMins"
                        value={userDelayInMinutes}
                        onChange={setUserDelayInMinutes}
                        type="number"
                    ></Input>
                </Box>
            </Row>
            <Row alignmentHorizontal="end">
                <Button onClick={addCertificatesHandler}>Add</Button>
            </Row>
            <Row width="125%">
                <Divider />
            </Row>
        </Column>
    );
}
