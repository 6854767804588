import React, { useEffect, useState, useCallback } from "react";
import Table, {
    TableRow,
    TableCell,
    TableActionBar,
} from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import CalmCodeSubmitModal from "../../modal/CalmCodeSubmitModal";
import {
    DEFAULT_SORT_COLUMN,
    DEFAULT_SORT_DIRECTION,
    DELAY_IN_MINUTES_TOOLTIP_TEXT,
    CALM_CODE_PROCESSING_MESSAGE,
    CALM_CODE_SUBMIT_ERROR_MESSAGE,
    CALM_CODE_LABEL,
    CALM_CODE_FIELD_NAME,
    DELAY_IN_MIN_LABEL,
    DELAY_IN_MIN_FIELD_NAME,
    ALERT_AUTHENTICATION_MESSAGE,
    CERTIFICATE_NAME_LABEL,
    CERTIFICATE_NAME_FIELD_NAME,
} from "../../../constants";
import AdminApiFactory from "../../../admin-api/AdminApiFactory";
import orderBy from "lodash/orderBy";

const LMS_TABLE_TITLES = [
    { headerName: CERTIFICATE_NAME_LABEL, sortId: CERTIFICATE_NAME_FIELD_NAME },
    { headerName: CALM_CODE_LABEL, sortId: CALM_CODE_FIELD_NAME },
    {headerName: DELAY_IN_MIN_LABEL, sortId: DELAY_IN_MIN_FIELD_NAME,},
];

export default function LMSTable({ lmsCertificateList }) {
    const [userLmsCertificateList, setUserLmsCertificateList] = useState(lmsCertificateList);
    const [CertificateNameFilter, setCertificateNameFilter] = useState("");
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState<TableSortDirection>(
        DEFAULT_SORT_DIRECTION
    );
    const [openModal, setOpenModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState<any>();

    const AdminApi = AdminApiFactory();

    // need this effect to "listen" to parent state change
    useEffect(() => {
        setUserLmsCertificateList(lmsCertificateList);

        return () => setUserLmsCertificateList([]);
    }, [lmsCertificateList]);

    // Note: CertificateName will be primary key for the dynamoDB table, it's safe to use it to generate uniqueId
    const getUniqueKey = (CertificateName, fieldName) => {
        return `${CertificateName}-${fieldName}`;
    };

    const onConfirmClose = useCallback(() => setOpenModal(false), []);

    const calmCodeChangeHandler = (CertificateName) => (inputCalmCode) => {
        console.log("userLmsCertificateList is", userLmsCertificateList);
        setUserLmsCertificateList(
            userLmsCertificateList.map((Certificate) =>
                Certificate.certificateName === CertificateName ? { ...Certificate, calmCode: inputCalmCode } : Certificate
            )
        );
    };

    const delayInMinutesChangeHandler = (CertificateName) => (inputDelayInMinutes) => {
        const inputDelayInMinutesToNumber = parseInt(inputDelayInMinutes) || 0;
        console.log(inputDelayInMinutes);
        setUserLmsCertificateList(
            userLmsCertificateList.map((Certificate) =>
                Certificate.certificateName === CertificateName ? { ...Certificate, delayInMinutes: inputDelayInMinutesToNumber } : Certificate
            )
        );
    };

    const onSortHandler = (event) => {
        setUserLmsCertificateList(
            orderBy(
                userLmsCertificateList,
                [event.sortColumn],
                [event.sortDirection === "ascending" ? "asc" : "desc"]
            )
        );
        setSortColumn(event.sortColumn);
        setSortDirection(event.sortDirection);
    };

    const submitHandler = () => {
        if (userLmsCertificateList && userLmsCertificateList.length) {
            setOpenModal(true);
            setResponseMessage(CALM_CODE_PROCESSING_MESSAGE);
            const { nodeId } = userLmsCertificateList[0];
            (async () => {
                try {
                    const updateCalmCodeResponse = (
                        await AdminApi.updateLMSCalmCodeForLMSCustomRoles(
                            nodeId,
                            userLmsCertificateList
                        )
                    ).data as unknown;
                    // setResponseMessage will be processed later inside Modal component
                    setResponseMessage(updateCalmCodeResponse);
                } catch (ex) {
                    console.error(
                        `Error posting calm code to /admin/LMSCertificate/${nodeId}`,
                        ex
                    );
                    if (ex.response && ex.response.status === 403) {
                        setResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
                    } else {
                        setResponseMessage(CALM_CODE_SUBMIT_ERROR_MESSAGE);
                    }
                }
            })();
        }
    };

    return (
        <Column>
            <CalmCodeSubmitModal
                open={openModal}
                responseMessage={responseMessage}
                onClose={onConfirmClose}
            />
            <Table
                headerRows={1}
                showDividers
                fixHeaderRows
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onSort={onSortHandler}
            >
                <TableActionBar>
                    <Input
                        key="searchInput"
                        type="text"
                        value={CertificateNameFilter}
                        placeholder="Filter by Certificate Name"
                        prefixIconTokens={filterTokenIcon}
                        onChange={setCertificateNameFilter}
                    />
                </TableActionBar>
                <TableRow key="calmCodeTitle">
                    {LMS_TABLE_TITLES.map(({ headerName, sortId }, index) => {
                        if (index === LMS_TABLE_TITLES.length - 1) {
                            return (
                                <TableCell
                                    key={headerName}
                                    header={true}
                                    sortColumn={sortId}>
                                    <Tooltip
                                        key={`${headerName}-tooltip`}
                                        position="top"
                                        title={DELAY_IN_MINUTES_TOOLTIP_TEXT}
                                    >
                                        <Text type="h100">
                                            {headerName + " "}
                                            <Icon tokens={helpKnockoutTokens} />
                                        </Text>
                                    </Tooltip>
                                </TableCell>
                            );
                        } else {
                            return (
                                <TableCell key={headerName} sortColumn={sortId}>
                                    {headerName}
                                </TableCell>
                            );
                        }
                    })}
                </TableRow>
                {userLmsCertificateList
                    .filter(({ certificateName }) =>
                        CertificateNameFilter ? certificateName.toLowerCase().includes(CertificateNameFilter.toLocaleLowerCase()) : true
                    )
                    .map(({ certificateName, calmCode, delayInMinutes }) => (
                        <TableRow key={getUniqueKey(certificateName, "row")}>
                            <TableCell key={getUniqueKey(certificateName, `certificateName-${certificateName}`)}>
                                {certificateName}
                            </TableCell>
                            <TableCell key={getUniqueKey(certificateName, "calmCode")}>
                                <Input
                                    type="text"
                                    value={calmCode}
                                    onChange={calmCodeChangeHandler(certificateName)}
                                />
                            </TableCell>
                            <TableCell key={getUniqueKey(certificateName, "delayInMinutes")}>
                                <Input
                                    type="number"
                                    value={delayInMinutes}
                                    onChange={delayInMinutesChangeHandler(certificateName)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
            </Table>
            <Row alignmentHorizontal="right">
                <Button onClick={submitHandler}>Submit</Button>
            </Row>
        </Column>
    );
}
