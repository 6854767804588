import React, { useState, useContext } from "react";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import RadioButton from "@amzn/meridian/radio-button";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import { SITE_SPECIFIC_TYPE, LMS_TYPE, NO_CALM_CODE_TYPE, NO_CALM_CODE_TOOLTIP } from "../../constants";
import { LMSLaborTrackingRole } from "../../admin-api/generated-src";
import "./LMSManualLaborTrackColumnList.scss";
import LMSManualLaborTrackContext from "../../store/lms-manual-labor-track-store";

const TYPE_TO_HEADER = {
  siteSpecific: "Site Specific Certificate",
  LMS: "LMS Certificate",
  noCalmCode: "Certificate with no Calm Code",
};

export default function LMSManualLaborTrackColumnList({
  manualLaborTrackCertificates,
  type
}) {
  const manualLaborTrackContext = useContext(LMSManualLaborTrackContext);
  const { employeeId, selectedCertificateName, setSelectedCertificateName, setShowModal, showRadioButton } = manualLaborTrackContext;

  let filteredManualLaborTrackCertificates: LMSLaborTrackingRole[] = [];
  switch (type) {
    case SITE_SPECIFIC_TYPE:
      filteredManualLaborTrackCertificates = manualLaborTrackCertificates.filter(role => !role.isLMS && role.calmCode);
      break;
    case LMS_TYPE:
      filteredManualLaborTrackCertificates = manualLaborTrackCertificates.filter(role => role.isLMS && role.calmCode);
      break;
    case NO_CALM_CODE_TYPE:
      filteredManualLaborTrackCertificates = manualLaborTrackCertificates.filter(role => !role.calmCode);
      break;
    default:
      filteredManualLaborTrackCertificates = manualLaborTrackCertificates;
  }

  const headerText = <Text>{TYPE_TO_HEADER[type]}</Text>;
  const noCalmCodeHelpIcon = <Icon tokens={helpKnockoutTokens} />;

  const selectCertificateNameHandler = (value) => {
    if (employeeId) {
      setSelectedCertificateName(value);
      setShowModal(true);
    }
  }

  return (
    <Column
      key={`${type}-column`}
      minWidth={200}
      minHeight={40}
    >
      <Row
        key="exampleIconRow"
        minWidth={335}
        minHeight={40}
      >
        <div className={`colorExampleIcon-${type}`} />
        {NO_CALM_CODE_TYPE === type ?
          <Tooltip position="right" title={NO_CALM_CODE_TOOLTIP}>
            <Text>
              {TYPE_TO_HEADER[type] + " "} {noCalmCodeHelpIcon}
            </Text>
          </Tooltip> : headerText}
      </Row>
      {filteredManualLaborTrackCertificates.map(({ certificateName }) => {
        return (
          <div className={`radioButtonContainer-${type}`} key={certificateName}>
            {NO_CALM_CODE_TYPE === type ? (
              <Text key={`${certificateName}-text`}>{certificateName}</Text>
            ) : (
              showRadioButton ?
                <RadioButton
                  key={`${certificateName}-radioButton`}
                  checked={selectedCertificateName === certificateName}
                  name="certificateName"
                  onChange={selectCertificateNameHandler}
                  value={certificateName}
                >
                  {certificateName}
                </RadioButton> : <Text key={`${certificateName}-${type}`}>{certificateName}</Text>)}

          </div>
        );
      })}
    </Column>
  );
}
