import AppLayout from "@amzn/meridian/app-layout";
import AdminMasthead from "../adminMasthead/AdminMasthead";
import AdminSideMenu from "../adminSideMenu/AdminSideMenu";
import EUAdminSideMenu from "../adminSideMenu/EUAdminSideMenu";
import Footer from "../footer/Footer";
import AdminContext from "../../store/admin-context";
import { useContext } from "react";

export default function AdminLayout({ children }) {
  const adminContext = useContext(AdminContext);
  const { isEUDomain, selectedNode } = adminContext;
  console.log("adminlayout isEUDomain:"+isEUDomain);

  //Upload Roster feature available for EU domain sites and sites starting with AMZL

  let menuComponent;
  if (isEUDomain || selectedNode?.startsWith("AMZL")) {
    menuComponent = EUAdminSideMenu;
  } else {
    menuComponent = AdminSideMenu;
  }

  return (
    <AppLayout
      headerComponent={AdminMasthead}
      sidebarComponent={menuComponent}
      footerComponent={Footer}
      backgroundColor="primary"
    >
      <AdminMasthead />
      {((isEUDomain || selectedNode?.startsWith("AMZL")) && <EUAdminSideMenu />) || <AdminSideMenu />}
      {children}
      <Footer />
    </AppLayout>
  );
}
